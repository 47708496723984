const navigation = [
  {
    name: "LinkedIn",
    style: "h-6 w-6",
    href: "https://www.linkedin.com/company/dr-doc-ai",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: "Twitter",
    style: "h-6 w-6",
    href: "https://twitter.com/junaid_builds",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
      </svg>
    ),
  },
  {
    name: "Youtube",
    style: "-ml-2 h-6 w-6",
    href: "https://www.youtube.com/@drdocai",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 310 310" {...props}>
        <path
          id="XMLID_823_"
          d="M297.917,64.645c-11.19-13.302-31.85-18.728-71.306-18.728H83.386c-40.359,0-61.369,5.776-72.517,19.938
		C0,79.663,0,100.008,0,128.166v53.669c0,54.551,12.896,82.248,83.386,82.248h143.226c34.216,0,53.176-4.788,65.442-16.527
		C304.633,235.518,310,215.863,310,181.835v-53.669C310,98.471,309.159,78.006,297.917,64.645z M199.021,162.41l-65.038,33.991
		c-1.454,0.76-3.044,1.137-4.632,1.137c-1.798,0-3.592-0.484-5.181-1.446c-2.992-1.813-4.819-5.056-4.819-8.554v-67.764
		c0-3.492,1.822-6.732,4.808-8.546c2.987-1.814,6.702-1.938,9.801-0.328l65.038,33.772c3.309,1.718,5.387,5.134,5.392,8.861
		C204.394,157.263,202.325,160.684,199.021,162.41z"
        />
      </svg>
    ),
  },
];

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="bg-black border-t border-gray-800 ">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              rel="noreferrer"
              target="_blank"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className={item.style} aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="text-gray-400">
          <div className="mt-8 md:order-1 md:mt-0 flex flex-row gap-2 ">
            <a
              href="https://www.drdoc.ai/"
              rel="noreferrer"
              target="_blank"
              className=" text-gray-400 hover:text-gray-700"
            >
              &copy; {year} Dr.Doc AI
            </a>
            <p className="text-center text-base text-gray-400">
              All rights reserved.
            </p>
          </div>
          <a
            rel="noreferrer"
            target="_blank"
            href="/terms"
            className="text-sm text-gray-400 hover:text-gray-700"
          >
            Terms &amp; Conditions
          </a>
          {" | "}
          <a
            rel="noreferrer"
            target="_blank"
            href="/privacy"
            className="text-sm text-gray-400 hover:text-gray-700"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
}
