// COMPONENTS
import DesktopNav from "../navigation/desktop";
import HamburgerNav from "../navigation/hamburger";
import Footer from "../footer";

export default function Base({ children, blackOut, noFooter }) {
  const style = blackOut ? "bg-black" : "";
  return (
    <div className={style}>
      <DesktopNav />
      <HamburgerNav />
      {children}
      {!noFooter && <Footer />}
    </div>
  );
}
